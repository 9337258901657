<template>
	<div>
		<v-navigation-drawer
			v-model="drawer"
			absolute
			temporary
			style="z-index: 999; position: fixed"
		>
			<v-list
				nav
				dense
			>
				<v-list-item-group
					v-model="group"
					active-class="teal--text text--darken-4"
				>
					<v-list-item
						v-scroll-to="'#home'"
						@click="($route.path != '/') ? $router.push('/') : ''"
					>
						<v-list-item-title>HOME</v-list-item-title>
					</v-list-item>
					<v-list-item
						v-scroll-to="'#strain'"
						@click="($route.path != '/') ? $router.push('/#strain') : ''"
					>
						<v-list-item-title>STRAIN</v-list-item-title>
					</v-list-item>
					<v-list-item
						v-scroll-to="'#about'"
						@click="($route.path != '/') ? $router.push('/#about') : ''"
					>
						<v-list-item-title>ABOUT US</v-list-item-title>
					</v-list-item>
					<v-list-item
						v-scroll-to="'#contact'"
						@click="($route.path != '/') ? $router.push('/#contact') : ''"
					>
						<v-list-item-title>CONTACT</v-list-item-title>
					</v-list-item>
					<v-list-item @click="$router.push('/blog')">
						<v-list-item-title>BLOG</v-list-item-title>
					</v-list-item>
				</v-list-item-group>
			</v-list>
		</v-navigation-drawer>
		<div class="navigation">
			<v-btn
				icon
				class="black"
				dark
				@click="drawer = true"
			>
				<v-icon>
					mdi-menu
				</v-icon>
			</v-btn>
		</div>
		<v-row
			class="menu py-6 mr-0"
			align="center"
			justify="end"
		>
			<a
				v-scroll-to="'#home'"
				style="border-right: 3px solid black"
				@click="($route.path != '/') ? $router.push('/') : ''"
			>
				<v-col class="menuButton mx-2">HOME</v-col>
			</a>
			<a
				v-scroll-to="'#strain'"
				style="border-right: 3px solid black"
				@click="($route.path != '/') ? $router.push('/#strain') : ''"
			>
				<v-col class="menuButton mx-2">STRAIN</v-col>
			</a>
			<a
				v-scroll-to="'#about'"
				style="border-right: 3px solid black"
				@click="($route.path != '/') ? $router.push('/#about') : ''"
			>
				<v-col class="menuButton mx-2">ABOUT US</v-col>
			</a>
			<a
				v-scroll-to="'#contact'"
				style="border-right: 3px solid black"
				@click="($route.path != '/') ? $router.push('/#contact') : ''"
			>
				<v-col class="menuButton mx-2">CONTACT</v-col>
			</a>
			<a @click="$router.push('/blog')">
				<v-col class="menuButton mx-2">BLOG</v-col>
			</a>
		</v-row>
	</div>
</template>

<script>
export default {
	data: () => (
		{
			drawer: false,
			group: null,
		}
	),
}
</script>

<style scoped>
	.menu{
		position: fixed;
		z-index: 99;
		right: 0;
		width: 100vw;
		/*background-color: rgb(252, 252, 252, 0.9);*/
	}
	.navigation {
		display: none;
		position: fixed;
		z-index: 99;
		right: 50px;
		top: 40px;
	}
	.menuButton{
		width: 150px;
		height: 40px;
		letter-spacing: 3px;
		text-align: center;
		font-size: 15px;
		cursor: pointer;
		color: grey;
	}
	.menuButton:hover{
		border: 1px solid black;
	}
	.menu a{
		text-decoration: none;
	}

	@media only screen and (max-width: 750px) {
		.menu {
			display: none;
		}
		.navigation {
			display: block;
		}
	}
</style>
