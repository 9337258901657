<template>
	<div class="wholesale">
		<menuBar />
		<v-row class="content">
			<v-col
				cols="12"
				xl="5"
				lg="5"
				md="5"
				sm="12"
				xs="12"
				class="contentLeft"
			>
				<img
					:src="$root.publicPath + 'image/wholesale.png'"
					width="90%"
				/>
				<div class="mx-3 px-9 py-6 wholesale-description">
					<span><b>Wholesale<br>Kratom</b></span>
					<p class="mt-3">
						If you plan to buy Kratom wholesale, it's critical that you go through a reputable vendor like Kratom Alpha with a track record of providing loyal customers safe, all-natural, and high quality Kratom. To carry the world's finest Kratom at your store, please contact us for information on wholesale purchasing.
					</p>
				</div>
			</v-col>
			<v-col
				cols="12"
				xl="7"
				lg="7"
				md="7"
				sm="12"
				xs="12"
				class="pt-6 px-15 contentRight"
			>
				<center>
					<div>
						<div class="bestOffer mb-3">
							<span style="color: #204531">BEST</span> OFFER
						</div>
						10 - 100 KILOS <span class="price">50<sup>$</sup></span><br>
						100 - 500 KILOS <span class="price">25<sup>$</sup></span><br>
						500 - 2000 KILOS <span class="price">23<sup>$</sup></span><br>
						<div class="bestPrice mt-3">
							<span style="font-size: 35px">>2000 KILOS</span><br>
							<span>contact our Sales for a Best Prices<br></span>
						</div>
					</div>
					<div class="mt-6 question">
						<v-textarea
							v-model="wholesale.question"
							solo
							no-resize
							label="Question"
						></v-textarea>
						<v-btn
							block
							@click="wholesale_question()"
						>
							Send
						</v-btn>
					</div>
				</center>
			</v-col>
		</v-row>
	</div>
</template>

<style scoped>
	.wholesale{
		background-color: rgb(253, 209, 0);
	}
	.wholesale-description {
		position: absolute;
		bottom: 20px;
		background-color: rgb(205, 221, 184)
	}
	.wholesale-description span{
		font-size:3em;
		color: #204531;
		line-height: 1;
	}
	.bestOffer{
		width: 250px;
		font-size: 35px;
		border-top: 1px solid white;
		border-bottom: 1px solid white;
	}
	.content {
		height: 100vh;
	}
	.contentRight{
		background-color: rgb(253, 209, 0);
		color: white;
		font-size: 25px;
		font-weight: bold;
	}
	.contentLeft {
		position: relative;
	}
	.price{
		color: #204531;
		font-size: 35px;
		margin-left: 20px;
	}
	.bestPrice{
		color: #204531;
		width: 300px;
		line-height: 1;
	}
	.question {
		margin: 0 40px;
	}
	@media only screen and (max-width: 750px) {
		.wholesale-description {
			bottom: auto;
			top: 220px;
			right: 10px;
			left: 10px;
		}
		.contentRight {
			margin-top: 120%;
			font-size: 20px;
		}
		.content {
			height: auto;
			width: 100%;
		}
		.question {
			margin: 0;
		}
		.price {
			font-size: 25px;
		}
		.bestPrice {
			margin-left: -20px;
		}
	}
</style>

<script>
import qs from 'qs'
import menuBar from './extended/menu.vue'

export default {
	components: {
		menuBar
	},
	data () {
		return {
			wholesale: {
				question: '',
			}
		}
	},
	methods: {
		wholesale_question () {
			const query_string = qs.stringify({
				view: 'cm',
				fs: 1,
				to: 'sales@kratomalpha.com',
				su: 'QUESTION',
				body: this.wholesale.question,
			})
			window.open("https://mail.google.com/mail/?"+query_string, "_blank")
		}
	}
}
</script>
